export const burnTokenDesc = [
  {
    header: "Burn Token",
    subHeader: "API reference for Burn Token",
    description: "To Burn Token.",
    services: [
      {
        url: "/api/hts/token/burn",
        type: "post",
        desc: "Burn token.",

        requestFieldDetails: {
          headerParam: [],
          bodyParam: [
            {
              fieldName: "clientId",
              dataType: "String",
              required: true,
              minLength: 3,
              maxLenth: 15,
              description:
                "The field represents the project user name which is used while registering",
            },
            {
              fieldName: "amount",
              dataType: "Integer",
              required: true,
              minLength: 3,
              maxLenth: 15,
              description: "Amount to be mentioned to burn",
            },
            {
              fieldName: "tokenId",
              dataType: "String",
              required: true,
              minLength: 3,
              maxLenth: 15,
              description: "The ID of the token",
            },
            {
              fieldName: "tokenOwnerPrivateKey",
              dataType: "String",
              required: true,
              minLength: 3,
              maxLenth: 15,
              description:
                "The operator private key is used to sign all transactions executed by this client, private keys are generated by an algorithm and are unique to one another.",
            },
          ],
        },
        responseFieldDetails: [
          {
            fieldName: "response",
            value: "11000",
            type: "String",
            description: "The burned token balance will appear here",
          },
        ],
      },
    ],
  },
];
