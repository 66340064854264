import React, { useEffect } from "react";
import { DateRangePicker } from "mui-daterange-picker";
import moment from "moment";
import styles from "../Home.module.css";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";

function DashDateRangePicker({ dateRange, setDateRange, handler }) {
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    setOpen(false);
  }, [dateRange?.endDate]);

  const toggle = () => setOpen(!open);

  return (
    <div>
      <button
        style={{
          display: "flex",
          alignItems: "center",
          backgroundColor: "white",
          padding: "20px",
          fontSize: "16px",
          fontWeight: "bold",
          borderRadius: "5px",
          border: "solid 1px lightgrey",
          outline: "none",
          position: "relative",
          marginRight: "20px",
          cursor: "pointer",
        }}
        onClick={toggle}
      >
        {moment(dateRange?.startDate).format("DD/MM")} -{" "}
        {moment(dateRange?.endDate).format("DD/MM")}
        <CalendarTodayIcon
          style={{ color: "green", marginLeft: "1rem", cursor: "pointer" }}
        />
      </button>
      <DateRangePicker
        wrapperClassName={styles.DashDateRangePicker}
        open={open}
        toggle={toggle}
        onChange={(range) => handler(range)}
      />
    </div>
  );
}

export default DashDateRangePicker;
