import { createSlice } from "@reduxjs/toolkit";
const adminSlice = createSlice({
  name: "admin",
  initialState: {
    adminName: "",
    getAllClients: [],
    getClientsLoading: false,
    transactionElements: 0,
    allclientElements: 0,
    fetchClients: [],
    fetchAllClientLoading: false,
    getTransactionsAct: [],
    TransactionsLoading: false,
    transactionElements: 0,
    allclientElements: 0,
    getHederaFeesList: [],
    feesLoading: false,
    feesElements: 0,
    getaddSaveAct: [],
    addsaveLoading: false,
    reportFeesList: [],
    reportFeesListLoading: false,
  },

  reducers: {
    getClientsAct: (state, { payload }) => {
      state.getAllClients = payload.response;
      state.getClientsLoading = payload.loading;
      state.allclientElements = payload.totalElements;
    },

    fetchAllAct: (state, { payload }) => {
      state.fetchClients = payload.response;
      state.fetchAllClientLoading = payload.loading;
    },
    TransactionsAct: (state, { payload }) => {
      state.getTransactionsAct = payload.response;
      state.TransactionsLoading = payload.loading;
      state.transactionElements = payload.totalElements;
    },
    getFeesAct: (state, { payload }) => {
      state.getHederaFeesList = payload.response;
      state.feesLoading = payload.loading;
      state.feesElements = payload.totalElements;
    },
    getAddsaveAct: (state, { payload }) => {
      state.getaddSaveAct = payload.response;
      state.addsaveLoading = payload.loading;
    },
    reportFeesAct: (state, { payload }) => {
      state.reportFeesList = payload.response;
      state.reportFeesListLoading = payload.loading;
    },
  },
});

export const {
  getClientsAct,
  fetchAllAct,
  TransactionsAct,
  getFeesAct,
  getAddsaveAct,
  reportFeesAct,
} = adminSlice.actions;

export const adminSelector = (state) => state.admin;
const adminReducer = adminSlice.reducer;
export default adminReducer;
