import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { Grid } from "@mui/material";
import { JsonEditor as Editor } from "jsoneditor-react";

import "jsoneditor-react/es/editor.min.css";
import LoadingPage from "../loading/Loading";

function ResponseComp({ respone, status, isLoading }) {
  return (
    <div>
      <Card fullwidth className="mock-API-response">
        <CardContent sx={{ padding: "0" }}>
          <Grid container rowSpacing={1}>
            <Grid item xs={6}>
              <Typography style={{ textAlign: "left" }}>Response</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography style={{ textAlign: "left" }}>
                Status : {status}
              </Typography>
            </Grid>
          </Grid>
          <br />
          {respone ? <Editor mode="code" value={respone} /> : ""}
        </CardContent>
      </Card>
      {isLoading ? <LoadingPage /> : ""}
    </div>
  );
}

export default ResponseComp;
