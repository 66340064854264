import React from "react";
import { styled, useTheme } from "@mui/material/styles";
import { Box, Button, Card, Grid } from "@mui/material";
import MiniDrawer from "../../components/navBar/Sidenav";
import { useDispatch, useSelector } from "react-redux";
import { adminSelector } from "../../redux/slice/adminSlice";
import { useState } from "react";
import { useEffect } from "react";
import {
  fetchAllClients,
  getHederaReportFeesApi,
} from "../../redux/action/adminAction";
import LoadingPage from "../../components/common/loading/Loading";
import DashDateRangePicker from "../../common/DashDateRangePicker";
import moment from "moment";

function FeesReport() {
  const dispatch = useDispatch();
  const [clientId, setClientId] = useState("");
  const [dateRange, setDateRange] = useState({
    startDate: new Date().setDate(new Date().getDate() - 6),
    endDate: new Date(),
  });

  const { reportFeesListLoading, fetchClients, reportFeesList } =
    useSelector(adminSelector);

  useEffect(() => {
    dispatch(fetchAllClients(setClientId));
  }, []);

  useEffect(() => {
    const req = {
      clientId: clientId,
      fromDate: moment(dateRange.startDate).format("YYYY-MM-DD"),
      toDate: moment(dateRange.endDate).format("YYYY-MM-DD"),
    };
    if (clientId) {
      dispatch(getHederaReportFeesApi(req));
    }
  }, [dateRange.endDate, clientId]);

  const dateRangeHandler = (e) => {
    setDateRange(e);
  };

  const clientHandler = (e) => {
    setClientId(e);
  };

  let arr = [
    {
      id: reportFeesList?.totalHederaFee,
      label: "Total Hedera fee (USD)",
    },
    {
      id: reportFeesList?.totalIhiFee,
      label: "Total IHI fee (USD)",
    },
    {
      id: reportFeesList?.totalFeeProfit,
      label: "Total Profit fee (USD)",
    },
  ];

  return (
    <div>
      <main className="respon">
        <div>
          <MiniDrawer />
          <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
            <DrawerHeader />
          </Box>
          <div className="wallettkn" style={{ marginBottom: "10px" }}>
            <select
              onChange={(e) => clientHandler(e.target.value)}
              style={{
                padding: "15.5px",
                borderRadius: "5px",
                border: "1px solid lightgrey",
                outline: "none",
                fontWeight: "bold",
                fontSize: "17px",
                cursor: "pointer",
              }}
            >
              {fetchClients?.map((e, i) => {
                return (
                  <option key={i} value={e.userName}>
                    {e.userName}
                  </option>
                );
              })}
            </select>

            <DashDateRangePicker
              handler={dateRangeHandler}
              dateRange={dateRange}
              setDateRange={setDateRange}
            />
          </div>
          <div style={{ padding: "1rem" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <h4>Transaction Fee Summary</h4>
              <p style={{ marginLeft: "10px", fontSize: "14px" }}>
                {`(${moment(dateRange.startDate).format(
                  "DD-MM-YYYY"
                )} to ${moment(dateRange.endDate).format("DD-MM-YYYY")})`}
              </p>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              {arr.map((e, i) => {
                return (
                  <div key={i} className="fee_box">
                    <p>{e.label}</p>
                    <p style={{ color: "palegreen", fontSize: "20px" }}>
                      {e.id}
                    </p>
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        {reportFeesListLoading && <LoadingPage />}
      </main>
    </div>
  );
}

export default FeesReport;
const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));
