import "./description.css";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Grid, Button, Link, Divider } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import { useState } from "react";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

function Description({ bodyDesc }) {
  const [expanded, setExpanded] = useState(true);
  const [expandedResField, setExpandedResField] = useState(true);
  const [expandedHeaderParam, setExpandedHeaderParam] = useState(true);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  const handleExpandClickResponseField = () => {
    setExpandedResField(!expandedResField);
  };

  const handleExpandClickUrlField = () => {
    setExpandedHeaderParam(!expandedHeaderParam);
  };
  return (
    <div className="body-content-section">
      <h1 className="">{bodyDesc?.header}</h1>
      <h4 className="">{bodyDesc?.subHeader}</h4>
      <p>{bodyDesc?.description}</p>

      {bodyDesc.services.map((ele, index) => {
        return (
          <>
            <h4>
              <code>{ele.url}</code>
            </h4>
            <p>{ele.desc}</p>
            {ele.requestFieldDetails.headerParam.length > 0 ? (
              <>
                <p className="Schema_tableHeader__BCZlp">
                  <b>URL fields</b> and example
                </p>
                <Grid container spacing={2} className="collapse-content">
                  <Grid item xs={10}>
                    {expandedHeaderParam ? "Collapse All" : "Expand All"}
                  </Grid>
                  <Grid item xs={2}>
                    <Button variant="text" onClick={handleExpandClickUrlField}>
                      {expandedHeaderParam ? (
                        <KeyboardArrowDownIcon />
                      ) : (
                        <KeyboardArrowUpIcon />
                      )}
                    </Button>
                  </Grid>
                </Grid>
                {expandedHeaderParam ? (
                  <div className="data-type-card">
                    {ele.requestFieldDetails.headerParam.map((headerparam) => {
                      return (
                        <>
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <Link href="#" underline="always">
                                {headerparam.fieldName}
                              </Link>
                              <span className="data-type">
                                {headerparam.dataType}
                              </span>
                            </Grid>
                          </Grid>

                          <p>{headerparam.description}</p>
                          <Divider />
                        </>
                      );
                    })}
                  </div>
                ) : (
                  ""
                )}
              </>
            ) : (
              ""
            )}

            {ele.requestFieldDetails.bodyParam.length > 0 ? (
              <>
                <p className="Schema_tableHeader__BCZlp">
                  <b>Request Fields</b> and example
                </p>
                <Grid container spacing={2} className="collapse-content">
                  <Grid item xs={10}>
                    {expanded ? "Collapse All" : "Expand All"}
                  </Grid>
                  <Grid item xs={2}>
                    <Button variant="text" onClick={handleExpandClick}>
                      {expanded ? (
                        <KeyboardArrowDownIcon />
                      ) : (
                        <KeyboardArrowUpIcon />
                      )}
                    </Button>
                  </Grid>
                </Grid>
                {expanded ? (
                  <div className="data-type-card">
                    {ele.requestFieldDetails.bodyParam.map((bodyparam) => {
                      return (
                        <>
                          <Grid container spacing={2}>
                            <Grid item xs={10}>
                              <Link href="#" underline="always">
                                {bodyparam.fieldName}
                              </Link>
                              <span className="data-type">
                                {bodyparam.dataType}
                              </span>
                            </Grid>
                          </Grid>

                          <p>{bodyparam.description}</p>
                          <Divider />
                        </>
                      );
                    })}
                  </div>
                ) : (
                  ""
                )}
              </>
            ) : (
              ""
            )}

            <p className="Schema_tableHeader__BCZlp">
              <b>Response Fields</b> and example
            </p>
            <Grid container spacing={2} className="collapse-content">
              <Grid item xs={10}>
                {expandedResField ? "Collapse All" : "Expand All"}
              </Grid>
              <Grid item xs={2}>
                <Button variant="text" onClick={handleExpandClickResponseField}>
                  {expandedResField ? (
                    <KeyboardArrowDownIcon />
                  ) : (
                    <KeyboardArrowUpIcon />
                  )}
                </Button>
              </Grid>
            </Grid>
            {expandedResField ? (
              <div className="data-type-card">
                {ele.responseFieldDetails.map((bodyparam) => {
                  return (
                    <>
                      <Grid container spacing={2}>
                        <Grid item xs={10}>
                          <Link href="#" underline="always">
                            {bodyparam.fieldName}
                          </Link>
                          <span className="data-type">
                            {bodyparam.dataType}
                          </span>
                        </Grid>
                      </Grid>

                      <p>{bodyparam.description}</p>
                      <Divider />
                    </>
                  );
                })}
              </div>
            ) : (
              ""
            )}
          </>
        );
      })}
    </div>
  );
}

export default Description;
