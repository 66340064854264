import { Grid } from "@mui/material";
import BodyComp from "./common/api-comp/BodyComp";
import ResponseComp from "./common/api-comp/ResponseComp";
import HedareComp from "./common/api-comp/HedareComp";

function Mock({ apidata, selectedTab }) {
  const reqData = apidata.reqBody;
  const resData = apidata.responseBody;
  const headerData = apidata.headrerBody;

  return (
    <div>
      <br />
      <Grid container rowSpacing={1}>
        {headerData.length > 0 ? (
          <Grid item xs={12}>
            <HedareComp headrerBody={headerData} selectedTab={selectedTab} />
          </Grid>
        ) : (
          ""
        )}

        {Object.keys(reqData).length > 0 ? (
          <Grid item xs={12} className="mock-API-card">
            <BodyComp
              value={reqData}
              disableTry={true}
              selectedTab={selectedTab}
              screenName={apidata.screenName}
            />
          </Grid>
        ) : (
          ""
        )}

        <Grid item xs={12}>
          <ResponseComp
            respone={resData}
            status={200}
            selectedTab={selectedTab}
            screenName={apidata.screenName}
          />
        </Grid>
      </Grid>
    </div>
  );
}

export default Mock;
