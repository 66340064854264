import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import TextField from "@mui/material/TextField";
import { DialogContent, Grid, IconButton } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { makeStyles } from "@mui/styles";
import Tooltip from "@mui/material/Tooltip";

function OpenDialog(props) {
  const { setOpen, open, inputValues, title, buttons, authorizeuser } = props;
  const [value, setValue] = React.useState(
    sessionStorage.getItem("authorized")
  );
  const [copyTitle, setCopyTitle] = React.useState("");
  const handleClose = () => {
    setOpen(false);
  };

  const onchangeInput = (e) => {
    setValue(e.target.value);
  };

  const copyText = (id) => {
    var copyText = document.getElementById(id);
    copyText.select();
    navigator.clipboard.writeText(copyText.value);
    setCopyTitle("copied!");
    setTimeout(() => {
      setCopyTitle("");
    }, 1000);
  };
  const clearAuth = () => {
    setValue("");
    sessionStorage.removeItem("auth");
  };

  return (
    <Dialog maxWidth={"xs"} onClose={handleClose} open={open}>
      <DialogTitle>
        {title}{" "}
        <IconButton sx={{ float: "right" }}>
          <CancelIcon
            sx={{ color: "#f3712c !important" }}
            onClick={() => handleClose()}
          />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Grid
          container
          spacing={2}
          sx={{ marginTop: "5px", textAlign: "center" }}
        >
          {inputValues.map((ele, i) => {
            return (
              <Grid item xs={12} key={i}>
                <TextField
                  sx={{ color: "white !important" }}
                  key={i}
                  id={ele.name}
                  // fullWidth
                  label={ele.name}
                  value={ele.name === "Authorize" ? value : ele.value}
                  variant="outlined"
                  onChange={ele.name === "Authorize" ? onchangeInput : ""}
                  InputProps={
                    ele.name === "Authorize"
                      ? ""
                      : {
                          endAdornment: (
                            <Tooltip title={copyTitle}>
                              <ContentCopyIcon
                                title={"copy"}
                                sx={{
                                  color: "#f3712c !important",
                                  paddingTop: "0px !important",
                                }}
                                onClick={() => copyText(ele.name)}
                              />
                            </Tooltip>
                          ),
                        }
                  }
                />
              </Grid>
            );
          })}
        </Grid>
      </DialogContent>
      <DialogActions>
        {buttons.map((ele, i) => {
          return (
            <Button
              sx={{
                backgroundColor: "#f3712c !important",
                color: "white !important",
              }}
              key={i}
              onClick={() =>
                ele === "Close"
                  ? handleClose()
                  : sessionStorage.getItem("auth")
                  ? clearAuth()
                  : value
                  ? authorizeuser(value)
                  : ""
              }
            >
              {ele === "Authorize"
                ? sessionStorage.getItem("auth")
                  ? "Logout"
                  : ele
                : ele}
            </Button>
          );
        })}
      </DialogActions>
    </Dialog>
  );
}

OpenDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};

export default OpenDialog;
