import React from "react";
import {
  HashRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import NotFoundPage from "./components/common/errorpage";
import GenerateToken from "./pages/auth/GenerateToken";
import HcsMessage from "./pages/hcs/PostHcsMessage";
import GetHcsMessage from "./pages/hcs/GetHcsMessage";
import CreateWallet from "./pages/hedera/CreateWallet";
import CreateNftToken from "./pages/hts/CreateNftToken";
import FileCreation from "./pages/hedera/FileCreation";
import TokenBalance from "./pages/hts/TokenBalance";
import CreateToken from "./pages/hts/CreateToken";
import BurnToken from "./pages/hts/BurnToken";
import MintToken from "./pages/hts/MintToken";
import CurrentTotalSupply from "./pages/hts/CurrentTotalSupply";
import AssociateKycUser from "./pages/hts/AssociateKycUser";
import TransferToken from "./pages/hts/TransferToken";
import AssociateTokenEnableKyc from "./pages/hts/AssociateToken&KYC";
import AdminLogin from "./pages/auth/AdminLogin";
import Dashboard from "./pages/admin/Dashboard";
import UserTranscation from "./pages/admin/UserTranscation";
import Fees from "./pages/admin/Fees";
import FeesReport from "./pages/admin/feesReport";

const Routers = () => {
  return (
    // <Router>
    <Routes>
      <Route exact path="/" element={<GenerateToken />} />
      <Route path="/generate-token" element={<GenerateToken />} />
      <Route path="/submit-a-message-to-a-topic" element={<HcsMessage />} />
      <Route path="/get-hcs-message" element={<GetHcsMessage />} />
      <Route path="/create-wallet" element={<CreateWallet />} />
      <Route path="/create-file" element={<FileCreation />} />
      <Route path="/create-nft-token" element={<CreateNftToken />} />
      <Route path="/token-balance" element={<TokenBalance />} />
      <Route path="/burn-token" element={<BurnToken />} />
      <Route path="/create-token" element={<CreateToken />} />
      <Route path="/current-total-supply" element={<CurrentTotalSupply />} />
      <Route path="/mint-token" element={<MintToken />} />
      <Route path="/transfer-token" element={<TransferToken />} />
      <Route path="/associate-kyc-user" element={<AssociateKycUser />} />
      <Route
        path="/associate-token&enable-kyc"
        element={<AssociateTokenEnableKyc />}
      />
      <Route path="*" element={<NotFoundPage />} />
      <Route element={<NotFoundPage />} />
      <Route path="/login" element={<AdminLogin />} />
      <Route path="/admin/dashboard" element={<Dashboard />} />
      <Route path="/admin/userTransaction" element={<UserTranscation />} />
      <Route path="/admin/fees" element={<Fees />} />
      <Route path="/admin/feesReport" element={<FeesReport />} />
    </Routes>
    // </Router>
  );
};

export default Routers;
