export const tokenGenerateInfo = [
  {
    header: "Token Generate",
    subHeader: "API reference for token generate",
    description: "Authorize a user to access a user related services.",
    services: [
      {
        url: "api/auth/token/generate",
        type: "post",
        desc: "Request login for a user with username and password. In response get the access token, token type and user related informations.",

        requestFieldDetails: {
          headerParam: [],
          bodyParam: [
            {
              fieldName: "username",
              dataType: "String",
              required: true,
              minLength: 3,
              maxLenth: 15,
              description:
                "username  is required and it should be pass with in request body. username minimum length should be 3 and maximum length can be 15.",
            },
            {
              fieldName: "password",
              dataType: "String",
              required: true,
              minLength: 3,
              maxLenth: 15,
              description:
                "password is required and it should be pass with in request body. password minimum length should be 3 and maximum length can be 15.",
            },
          ],
        },
        responseFieldDetails: [
          {
            fieldName: "accessToken",
            value:
              "eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJpaGlfdGVzdCIsImlhdCI6MTY1NDA3OTk0NSwiZXhwIjoxNjU0MTY2MzQ1fQ.7fM-Tt-rya1SZjaOKpVTIU3FHDcKP381vx50K1uhc1_HP3znERpDEJ4-uKmUPEw4IF040kEjk1Noq6cGDbE8EA",
            dataType: "String",
            description:
              "accessToken is for user authentication to access the user servicess.",
          },
          {
            fieldName: "tokenType",
            value: "Bearer",
            dataType: "String",
            description:
              "tokenType is for user authentication need to send with accessToken.",
          },
          {
            fieldName: "id",
            value: "82cb9a7a-02ee-458f-af17-a10ffd4f75c4",
            dataType: "String",
            description:
              "id is for user. By using id user can retrive any user related information as per the request body or request param.",
          },
          {
            fieldName: "username",
            value: "ihi_test",
            dataType: "String",
            description:
              "username is for user. By using username, user can retrive any user related information as per the request body or request param.",
          },
          {
            fieldName: "email",
            value: "ihitest@yopmail.com",
            dataType: "String",
            description:
              "email is for user. By using email, user can retrive any user related information as per the request body or request param.",
          },
          {
            fieldName: "role",
            value: ["ROLE_USER"],
            dataType: "Array",
            description:
              "role is for user. Based on the user role we can differentiate services.",
          },
          {
            fieldName: "refreshToken",
            value: "3eebc79d-712e-4824-91d0-62f33080c0ce.",
            dataType: "String",
            description:
              "A refresh token is a credential artifact that lets a client application get new access tokens without having to ask the user to log in again.",
          },
        ],
      },
    ],
  },
];
