import axios from "axios";
import { Router } from "react-router-dom";
// import router from "next/router";
import { ADMIN_AUTH_BASE_URL, ADMIN_BASE_URL } from "../../common/constants";

export const RefreshToken = () => {
  const refreshToken =
    typeof window !== "undefined" ? sessionStorage.getItem("refreshToken") : "";
  axios
    .post(`${ADMIN_AUTH_BASE_URL}refreshtoken/${refreshToken}`)
    .then((token) => {
      const { data } = token;
      sessionStorage.setItem("accessToken", "Bearer " + data.accessToken);
      sessionStorage.setItem("refreshToken", data.refreshToken);
      Router.reload(window.location.pathname);
    });
};

export const APIService = async (method, url, body) => {
  // console.log(ADMIN_BASE_URL);
  const userId = sessionStorage.getItem("userId");
  const roles = sessionStorage.getItem("roles");
  const accessToken = sessionStorage.getItem("accessToken");
  const obj = {
    ROLE_SUPER_ADMIN: ADMIN_BASE_URL,
  };
  function baseUrl(roles) {
    return obj[roles];
  }
  if (window.navigator.onLine) {
    return await axios({
      method: method,
      baseURL: baseUrl(roles),
      url: url,
      headers: {
        Authorization: accessToken,
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      data: body,
    })
      .then((e) => {
        const { data } = e;
        if (data.status === 200) {
          return {
            status: "success",
            data: data,
          };
        } else {
          return {
            status: "error",
            message: data.message,
          };
        }
      })
      .catch((e) => {
        if (e.message === "Network Error") {
          Router.push("/network-issue");
        }
        const refreshToken =
          typeof window !== "undefined"
            ? sessionStorage.getItem("refreshToken")
            : "";
        if (e.response.status === 401) {
          axios
            .post(`${ADMIN_AUTH_BASE_URL}/refreshtoken/${refreshToken}`)
            .then((token) => {
              const { data } = token;

              sessionStorage.setItem(
                "accessToken",
                "Bearer " + data.accessToken
              );
              sessionStorage.setItem("refreshToken", data.refreshToken);
              Router.reload(window.location.pathname);
            });
        }
        if (userId === null || undefined || "") {
          Router.push("/login");
        }
      });
  } else {
    Router.push("/internet-issue");
  }
};
