import axios from "axios";
import { errorToast, successToast } from "../../common/helper";
import { APIService } from "../api/APIService";
import {
  getClientsAct,
  fetchAllAct,
  TransactionsAct,
  getFeesAct,
  getAddsaveAct,
  reportFeesAct,
} from "../slice/adminSlice";

export function getAllClientsApi(page, size) {
  return async (dispatch) => {
    dispatch(getClientsAct({ loading: true }));
    APIService("get", `clients/all?page=${page}&size=${size}`)
      .then((e) => {
        const { status, message, response, totatlElements } = e.data;
        // console.log(e.data);
        if (status === 200) {
          dispatch(
            getClientsAct({
              loading: false,
              response: response,
              totalElements: totatlElements,
            })
          );
        } else {
          errorToast(message);
          dispatch(getClientsAct({ loading: false }));
        }
      })
      .catch((e) => {
        errorToast(e?.response?.data?.message);
        dispatch(getClientsAct({ loading: false }));
      });
  };
}

export function fetchAllClients(setClientId) {
  return async (dispatch) => {
    dispatch(fetchAllAct({ Loading: true }));
    APIService("get", `clientids`)
      .then((e) => {
        const { status, message, response } = e.data;
        if (status === 200) {
          dispatch(fetchAllAct({ Loading: false, response: response }));
          setClientId(response[0]?.userName);
        } else {
          errorToast(message);
          dispatch(fetchAllAct({ Loading: false }));
        }
      })
      .catch((e) => {
        errorToast(e?.response?.data?.message);
        dispatch(fetchAllAct({ Loading: false }));
      });
  };
}

export function getTransactionsApi(req) {
  return async (dispatch) => {
    dispatch(TransactionsAct({ loading: true }));
    APIService("POST", `hedera/transactions`, req)
      .then((e) => {
        // console.log(e, "headra transaction");
        const { status, message, response, totatlElements } = e.data;
        if (status === 200 && response != null) {
          // successToast(" Transaction Successfull", { toastId: "transaction" });
          dispatch(
            TransactionsAct({
              loading: false,
              response: response,
              totalElements: totatlElements,
            })
          );
        } else {
          errorToast(message);
          dispatch(TransactionsAct({ loading: false }));
        }
      })
      .catch((e) => {
        dispatch(TransactionsAct({ loading: false }));
        // errorToast("unsuccessful");
      })
      .catch((e) => {
        errorToast(e?.data?.message);
      });
  };
}

export function headraFeesApi(page, size, searchText, setFeesArr) {
  let endPoint = searchText
    ? `hedera/fees/list?searchName=${searchText}&page=${page}&size=${size}`
    : `hedera/fees/list?page=${page}&size=${size}`;
  return async (dispatch) => {
    dispatch(getFeesAct({ loading: true }));
    APIService("get", endPoint)
      .then((e) => {
        const { status, message, response, totatlElements } = e.data;
        if (status === 200) {
          setFeesArr(response);
          dispatch(
            getFeesAct({
              loading: false,
              response: response,
              totalElements: totatlElements,
            })
          );
        } else {
          errorToast(message);
          dispatch(getFeesAct({ loading: false }));
        }
      })
      .catch((e) => {
        errorToast(e?.response?.data?.message);
        dispatch(getFeesAct({ loading: false }));
      });
  };
}

export function feesHandlerApi(title, data, size, setFeesArr) {
  const endPoint =
    title === "update" ? `hedera/fees/update` : `hedera/fees/save`;

  return async (dispatch) => {
    dispatch(getAddsaveAct({ loading: true }));
    APIService("POST", endPoint, data)
      .then((e) => {
        const { status, message } = e.data;
        if (status === 200) {
          successToast(message);
          dispatch(getAddsaveAct({ loading: false }));
          dispatch(headraFeesApi(0, size, null, setFeesArr));
        } else {
          errorToast(message);
        }
      })
      .catch((e) => {
        // toast.warning(e?.data?.message);
      });
  };
}

export function getHederaReportFeesApi(req) {
  return async (dispatch) => {
    dispatch(reportFeesAct({ loading: true }));
    APIService("POST", `hedera/transactions/day/fee`, req)
      .then((e) => {
        const { status, message, response } = e.data;
        if (status === 200) {
          dispatch(
            reportFeesAct({
              loading: false,
              response: response,
            })
          );
        } else {
          errorToast(message);
          dispatch(reportFeesAct({ loading: false }));
        }
      })
      .catch((e) => {
        dispatch(reportFeesAct({ loading: false }));
        // errorToast("unsuccessful");
      })
      .catch((e) => {
        errorToast(e?.data?.message);
      });
  };
}
