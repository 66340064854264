export const FileCreateDesc = [
  {
    header: "File Creation",
    subHeader: "",
    description: "Create a file in hedera blockchain.",
    services: [
      {
        url: "/api/nft/v2/file/create",
        type: "post",
        desc: "This api is used to create a file in hedera blockchain",

        requestFieldDetails: {
          headerParam: [],
          bodyParam: [
            {
              fieldName: "clientId",
              dataType: "String",
              required: true,
              minLength: 3,
              maxLenth: 15,
              description:
                "clientId  is required and it should be pass with in request body. ",
            },
            {
              fieldName: "fileContent",
              dataType: "String",
              required: true,
              minLength: 3,
              maxLenth: 15,
              description:
                "fileContent is required and it should be pass with in request body. ",
            },
            {
              fieldName: "userId",
              dataType: "String",
              required: true,
              minLength: 3,
              maxLenth: 15,
              description:
                "userId is required and it should be pass with in request body. ",
            },
          ],
        },
        responseFieldDetails: [
          {
            fieldName: "response",
            value: "0.0.45966788",
            dataType: "String",
            description:
              "Response values is file id which we need to use in hts service.",
          },
        ],
      },
    ],
  },
];
