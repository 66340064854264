import Description from "../../components/common/Description";
import { burnTokenDesc } from "../../components/common/json-data/BurnTokenDesc";
import Grid from "@mui/material/Grid";
import ApiTest from "../../components/common/ApiTest";
import React from "react";
import { styled, useTheme } from "@mui/material/styles";
import MiniDrawer from "../../components/navBar/Sidenav";
import { Box } from "@mui/material";
import { BurnTokenApi } from "../../components/common/constants";

function BurnToken() {
  return (
    <div style={{ display: "flex" }}>
      <MiniDrawer />
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Description bodyDesc={burnTokenDesc[0]} />
          </Grid>
          <Grid item xs={6}>
            <ApiTest apidata={BurnTokenApi} />
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}

export default BurnToken;

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));
