import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { Grid, Button, Tabs } from "@mui/material";

import { useState } from "react";

import Tab from "@mui/material/Tab";
import Interactive from "../Interactive";
import Mock from "../Mock";

function ApiTest({ apidata }) {
  const [selectedTab, setSelectedTab] = useState(0);

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <div>
      <Grid container rowSpacing={1}>
        <Grid item xs={12}>
          <Card fullwidth className="dark-card">
            <CardContent>
              <Grid container spacing={1} className="API-link-section">
                <Grid item xs={9}>
                  <Typography
                    sx={{ fontSize: 14 }}
                    color="text.secondary"
                    gutterBottom
                  >
                    {apidata.HeaderUrl}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Button
                    className="btn yellow-btn"
                    variant="outlined"
                    sx={{
                      color: "#000",
                      fontSize: "14px",
                      fontWeight: "700",
                      background: "#ffb606 none repeat scroll 0 0",
                    }}
                  >
                    {apidata.methodType}
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Card fullwidth className="API-input-card">
            <Tabs
              onChange={handleChange}
              value={selectedTab}
              aria-label="Tabs where selection follows focus"
              selectionFollowsFocus
            >
              <Tab label="Mock" />
              <Tab label="Interactive" />
            </Tabs>
            {selectedTab === 0 ? (
              <Mock selectedTab={selectedTab} apidata={apidata} />
            ) : (
              <Interactive apidata={apidata} selectedTab={selectedTab} />
            )}
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}

export default ApiTest;
