import logo from "./logo.svg";
import "./App.css";
import Sidenav from "./components/navBar/Sidenav";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Routers from "./routes";
import { Provider } from "react-redux";
import store from "./redux/store";

function App() {
  return (
    <>
      <Provider store={store}>
        <Routers />
        <ToastContainer />
      </Provider>
    </>
  );
}

export default App;
