export const EnableKYCDesc = [
  {
    header: "Associate Token and Enable Kyc User",
    subHeader: "API reference to Associate Token and Enable Kyc User",
    description: "To Associate Token and Enable Kyc User.",
    services: [
      {
        url: "api/hts/token/users/associatekyc",
        type: "post",
        desc: "To Associate Token and Enable Kyc User.",

        requestFieldDetails: {
          headerParam: [],
          bodyParam: [
            {
              fieldName: "adminOperatorId",
              dataType: "String",
              required: true,
              minLength: 3,
              maxLenth: 15,
              description: "The Id of the admin wallet",
            },
            {
              fieldName: "adminOperatorKey",
              dataType: "String",
              required: true,
              minLength: 3,
              maxLenth: 15,
              description:
                "The key is used to sign all transactions executed by this client, private keys are generated by an algorithm and are unique to one another.",
            },
            {
              fieldName: "operatorId",
              dataType: "String",
              required: true,
              minLength: 3,
              maxLenth: 15,
              description: "The Id of the user wallet",
            },
            {
              fieldName: "operatorKey",
              dataType: "String",
              required: true,
              minLength: 3,
              maxLenth: 15,
              description:
                "The operator key is used to sign all transactions executed by this client, private keys are generated by an algorithm and are unique to one another.",
            },
            {
              fieldName: "clientId",
              dataType: "String",
              required: true,
              minLength: 3,
              maxLenth: 15,
              description:
                "project user name which we provide to the other projects",
            },
            {
              fieldName: "tokenId",
              dataType: "String",
              required: true,
              minLength: 3,
              maxLenth: 15,
              description: "The ID of the token Id",
            },
          ],
        },
        responseFieldDetails: [
          {
            fieldName: "response",
            message: "Associated token and enable kyc successfully",
            value: "null",
            type: "String",
            description: "The minted token balance will appear here",
          },
        ],
      },
    ],
  },
];
