export const transferTokenDesc = [
  {
    header: "Transfer Token",
    subHeader: "API reference for transfer token",
    description: "To transfer amount from one token id to another.",
    services: [
      {
        url: "/api/hts/token/transfer",
        type: "post",
        desc: "Transfer token.",

        requestFieldDetails: {
          headerParam: [],
          bodyParam: [
            {
              fieldName: "amount",
              dataType: "Float",
              required: true,
              minLength: 3,
              maxLenth: 15,
              description: "Amount to be tarnfer to token",
            },
            {
              fieldName: "clientId",
              dataType: "String",
              required: true,
              minLength: 3,
              maxLenth: 15,
              description:
                "The field represents the project user name which is used while registering",
            },
            {
              fieldName: "fromSenderId",
              dataType: "String",
              required: true,
              minLength: 3,
              maxLenth: 15,
              description:
                "From user treasuryAccountId which generated during the time of token creation.",
            },
            {
              fieldName: "fromSenderKey",
              dataType: "String",
              required: true,
              minLength: 3,
              maxLenth: 15,
              description:
                "From user treasuryPrivateKey which generated during the time of token creation.",
            },
            {
              fieldName: "toAccountId",
              dataType: "String",
              required: true,
              minLength: 3,
              maxLenth: 15,
              description:
                "To user treasuryAccountId which generated during the time of token creation.",
            },
            {
              fieldName: "tokenId",
              dataType: "String",
              required: true,
              minLength: 3,
              maxLenth: 15,
              description: "Token id of from user.",
            },
          ],
        },
        responseFieldDetails: [
          {
            fieldName: "response",
            value: "11000",
            type: "String",
            description: "The burned token balance will appear here",
          },
        ],
      },
    ],
  },
];
