import { combineReducers, configureStore } from "@reduxjs/toolkit";
import adminReducer from "./slice/adminSlice";
import authReducer from "./slice/authSlice";

const rootReducer = combineReducers({
  auth: authReducer,
  admin: adminReducer
});

const store = configureStore({ reducer: rootReducer });
export default store;
